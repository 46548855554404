import React from 'react'
import Layout from '../../components/layout'
import Meta from '../../components/meta'
import { graphql } from 'gatsby'
import filterLocale from '../../components/utils/filter-locale'
import Hero from '../../components/dolphin-commercial/hero'
import PageDescription from '../../components/dolphin-commercial/page-description'
import Products from '../../components/dolphin-commercial/products'
import TwoBanner from '../../components/dolphin-commercial/two-banner'
import Specifications from '../../components/dolphin-commercial/specifications'
import QuoteRequest from '../../components/dolphin-commercial/quote-request'
import FindDealer from '../../components/find-dealer'
import Poseidon from '../../components/dolphin-commercial/poseidon'
import localeCheck from '../../components/utils/locale-check'

const Commercial = ({
	data: {
		commercial,
		bannerLgImage,
		productImage,
		powerhouse,
		whichmodel,
		specifications,
		findDealer,
		poseidon,
		stats,
		bannerSmImage,
	},
}) => {
	const locale = 'en-AU'
	const defaultLocale = 'en-AU'

	const [{ node }] = filterLocale(commercial.edges, locale)
	const [{ node: bannerlg }] = filterLocale(bannerLgImage.edges, locale)
	const [{ node: bannersm }] = filterLocale(bannerSmImage.edges, locale)
	const [{ node: powerHouse }] = filterLocale(powerhouse.edges, locale)
	const [{ node: whichModel }] = filterLocale(whichmodel.edges, locale)
	const [{ node: finddealer }] = filterLocale(findDealer.edges, locale)
	const [{ node: commercialposeidon }] = filterLocale(poseidon.edges, locale)

	const filterAllByLocale = (items, locale) => {
		return items.filter((item, i) => {
			return item.node.node_locale === locale
		})
	}
	const specs = filterAllByLocale(specifications.edges, locale)
	const poseidonStats = filterAllByLocale(stats.edges, locale)

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		slug,
		body: pagebody,
		media: brandUrl,
		banner: {
			mainActionText,
			mainActionUrl,
			secondActionText,
			secondActionUrl,
			description,
			videoBanner: videourl,
		},
	} = node

	const bannerSources = [
		{
			...bannerlg.backgroundImage.fluid,
			srcSet: `${bannerlg.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${bannerlg.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...bannersm.backgroundImage.fluid,
			srcSet: `${bannersm.backgroundImage.fluid.src} 524w`,
			srcSetWebp: `${bannersm.backgroundImage.fluid.srcWebp} 524w`,
			media: `(max-width: 524px)`,
		},
	]

	const checkedLocale = localeCheck(locale, defaultLocale)

	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>
			<Hero
				{...{
					mainActionText,
					mainActionUrl,
					secondActionText,
					secondActionUrl,
					description,
					videourl,
					brandUrl,
					bannerSources,
				}}
			/>
			<PageDescription pagebody={pagebody} />
			<Products locale={locale} productImage={productImage} checkedLocale={checkedLocale}/>
			<TwoBanner powerhouse={powerHouse} whichmodel={whichModel} />
			<Specifications specs={specs} />
			<QuoteRequest locale={locale} />
			{/* <FindDealer finddealer={finddealer} /> */}
			<Poseidon poseidon={commercialposeidon} stats={poseidonStats} />
		</Layout>
	)
}

export const commercialQuery = graphql`
	query commercialQuery {
		commercial: allContentfulPage(
			filter: {contentful_id: {eq: "7qxCG7B9uDecvQEKmVk9z5"}}
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					slug
					node_locale
					body {
						json
					}
					media {
						description
						file {
							url
						}
					}
					banner {
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						description {
							json
						}
						videoBanner {
							file {
								url
							}
						}
					}
				}
			}
		}
		bannerLgImage: allContentfulPage(
			filter: {contentful_id: {eq: "7qxCG7B9uDecvQEKmVk9z5"}}
		) {
			edges {
				node {
					backgroundImage {
						fluid(
							maxWidth: 1920
							maxHeight: 1080
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		bannerSmImage: allContentfulPage(
			filter: {contentful_id: {eq: "7qxCG7B9uDecvQEKmVk9z5"}}
		) {
			edges {
				node {
					backgroundImage {
						fluid(
							maxWidth: 524
							maxHeight: 300
							quality: 60
							cropFocus: CENTER
							resizingBehavior: FILL
						) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		productImage: allContentfulProduct {
			edges {
				node {
					node_locale
					seoTitle
					seoDescription
					slug
					sku
					price
					model
					brand
					active
					series
					categories {
						title: seoTitle
						slug
					}
					shippingClass
					images {
						fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
							src
							srcWebp
						}
						description
					}
				}
			}
		}
		powerhouse: allContentfulBanner(
				filter: {contentful_id: {eq: "4KeP8UZlcr4fLKuHWYaAml"}}
			) {
				edges {
					node {
						node_locale
						description {
							json
						}
					}
				}
			}
		whichmodel: allContentfulBanner(
			filter: {contentful_id: {eq: "31h1HfMKcA3QOLdqkQEEu4"}}
		) {
			edges {
				node {
					node_locale
					description {
						json
					}
					mainActionText
					mainActionUrl
				}
			}
		}
		specifications: allContentfulFeaturedItems(
			filter: {
				contentful_id: {
					in: ["1jHYDiMUZL1k0qHlJh2KGZ", "6Ve9fEYGgXTysG8sBclGjS", "3ranBcVikXq1glmLDiCH40"]
				}
			}
		) {
			edges {
				node {
					title
					node_locale
					items {
						name
					}
				}
			}
		}
		findDealer: allContentfulBanner(
			filter: {contentful_id: {eq: "3U6R6CcNXvD5DN1xW5gf0K"}}
		) {
			edges {
				node {
					mainActionText
					mainActionUrl
					secondActionText
					secondActionUrl
					hook
					node_locale
					backgroundImage {
						file {
							url
						}
					}
					description {
						json
					}
				}
			}
		}
		poseidon: allContentfulBanner(
			filter: {contentful_id: {eq: "4xxarRlorH1lpjQaTdEZBe"}}
		) {
			edges {
				node {
					node_locale
					description {
						json
					}
					image {
						description
						file {
							url
						}
					}
					backgroundImage {
						file {
							url
						}
					}
					mainActionText
					mainActionUrl
				}
			}
		}
		stats: allContentfulFeaturedItems(
			filter: {contentful_id: {eq: "34wwfqboQZWzz76KieBxns"}}
		) {
			edges {
				node {
					title
					node_locale
					items {
						name
						body {
							json
						}
					}
				}
			}
		}
	}
`

export default Commercial
